import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = theme => ({
  title: {
    zIndex: 2,
  },
  TitleSquare: {
    border: `8px solid ${theme.palette.text.blue}`,
    height: '100px',
    width: '65px',
    zIndex: 1,
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      height: '70px',
      width: '50px',
    },
  },
});

function HeroTitle(props) {
  const { title, variant, component, color, align, classes } = props;
  return (
    <Box
      component="div"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        className={classes.title}
        variant={variant}
        component={component}
        color={color}
        align={align}
      >
        {title}
      </Typography>
    </Box>
  );
}

HeroTitle.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(HeroTitle);
