import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import HeroTitle from 'components/hero/HeroTitle';
import BtnFunc from 'components/buttons/BtnFunc';
import palette from 'styles/palette';
import Helmet from 'react-helmet';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  toolBar: {
    padding: '0',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    height: 'auto',
    backgroundColor: theme.palette.background.xdblue,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btn: {
    color: theme.palette.text.green,
    backgroundColor: theme.palette.background.default,
    borderRadius: '0px',
    padding: '5px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.text.green,
      color: theme.palette.background.default,
    },
  },
  margin: {
    margin: `${theme.spacing(1.5)}px ${theme.spacing(0)}px`,
    width: '100%',
    '& label': {
      color: theme.palette.background.dgrey,
      position: 'relative',
      '&.Mui-focused': {
        color: `${fade(theme.palette.background.green, 0.8)}`,
      },
    },
    '& .Mui-error': {
      '& input': {
        color: 'red',
        boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.error.main,
        '&:focus': {
          boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        },
      },
    },
  },
  helperText: {
    fontSize: '14px',
    color: '#6b737b',
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
  errorWrapper: {
    paddingTop: theme.spacing(0.6),
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(100%)',
  },
  panelRoot: {
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    marginBottom: theme.spacing(2),
  },
  panelExpanded: {
    backgroundColor: '#fff !important',
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  panelDisabled: {
    backgroundColor: '#fff !important',
  },
  panelSummary: {
    minHeight: 'auto !important',
  },
  panelSummaryContent: {
    margin: '0 !important',
  },
  panelDetails: {
    padding: '0px',
  },
  heading: {
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobForm = () => {
  return <div id="grnhse_app"></div>;
};

function Application(props) {
  const { title, jobId } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const head = [];
  head.pageScripts = [
    {
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=ovoenergy',
      type: 'text/javascript',
      async: true,
    },
    {
      src: '/application-form.js',
      type: 'text/javascript',
      defer: true,
    },
  ];
  head.pageMeta = [
    {
      id: 'gh_id',
      name: 'gh:id',
      content: jobId,
    },
  ];
  const meta = [...head.pageMeta];
  const scripts = [...head.pageScripts];
  return (
    <React.Fragment>
      <BtnFunc
        handleClick={handleClickOpen}
        content="Apply now"
        color={palette.text.pink}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Helmet script={scripts} meta={meta}></Helmet>
        <Box className={classes.content} px={[0, 0, 0, 16]}>
          <Container maxWidth="lg">
            <Box display="flex" justifyContent="flex-end" py={2}>
              <IconButton
                edge="start"
                color="secondary"
                onClick={handleClose}
                aria-label="close"
                className={classes.btn}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <HeroTitle
                title="APPLY"
                color="textSecondary"
                variant="h1"
                component="h1"
                align="center"
              />
            </Box>
            <Box py={3}>
              <Typography
                component="h1"
                variant="h6"
                color="textSecondary"
                align="center"
              >
                {title}
              </Typography>
            </Box>
            <JobForm />
          </Container>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

Application.propTypes = {
  title: PropTypes.string.isRequired,
  jobId: PropTypes.number.isRequired,
};

export default Application;
