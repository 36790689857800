import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import StickyBox from 'react-sticky-box';
import { withStyles } from '@material-ui/styles';
import Layout from 'components/layout';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import JobHero from 'components/hero/JobHero';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ReactHtmlParser from 'react-html-parser';
import Application from 'components/application/embedded';
import withWidth from '@material-ui/core/withWidth';
import { compose, isMobileView } from 'utils';
import JobHelmet from 'components/metaschema/JobHelmet';

import HeroSecondaryPage from 'components/hero/HeroSecondaryPage';

import defaultHeroImage from 'images/jobs_header_img.png';

const styles = theme => ({
  breadcrumbWrapper: {
    borderBottom: `1px solid ${theme.palette.text.input}`,
    padding: `${theme.spacing(1.5)}px 0px`,
  },
  breadcrumb: {
    color: theme.palette.text.black,
  },
  separator: {
    color: theme.palette.text.input,
  },
  breadcrumbUnderline: {
    borderBottom: `2px solid ${theme.palette.text.green}`,
    paddingBottom: theme.spacing(0.2),
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: theme.palette.text.green,
      borderBottom: '0px',
    },
  },
  contentLeft: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  contentRight: {
    width: '100%',
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  jobDetails: {
    overflowWrap: 'anywhere',
    wordBreak: 'break-word',
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
});

function Job({ data, classes, width }) {
  const [heroImage, setHeroImage] = useState();

  function unescapeHTML(html) {
    if (typeof window === 'undefined') {
      return;
    }
    var escapeEl = document.createElement('div');
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  }

  useEffect(() => {
    const dpId = data.greenhouseJob.departments[0].gh_Id;
    const matchingDepartment = data.allPrismicDepartment.edges.find(
      edge => edge.node.data.greenhouse_id === dpId
    );

    if (matchingDepartment) {
      setHeroImage(matchingDepartment.node.data.banner_image.url);
    }
  }, [data.greenhouseJob.departments, data.allPrismicDepartment.edges]);

  return (
    <Layout headerOpacity="0.9">
      <JobHelmet ghdata={data} dpId={data.greenhouseJob.departments[0].gh_Id} />
      <Box component="div">
        <HeroSecondaryPage
          heroTitle={
            data.greenhouseJob.title
              ? data.greenhouseJob.title
              : 'Job Description'
          }
          heroImage={heroImage ? heroImage : defaultHeroImage}
          heroContent="Here is everything you need to know about the job"
        />
        <Box className={classes.greyBg}>
          <Container maxWidth="lg">
            <Grid container justify="space-between">
              <Grid item md={7} className={classes.contentLeft}>
                <Box
                  mt={3}
                  className={`${classes.jobDetails} jobDetailsEmbedded`}
                >
                  <Typography component="h1" variant="h3">
                    {data.greenhouseJob.title}
                  </Typography>
                  <Typography component="div" variant="body1">
                    {ReactHtmlParser(unescapeHTML(data.greenhouseJob.content))}
                  </Typography>
                  <Grid container justify="space-between">
                    <Grid item xs={12}>
                      <Box mt={[1, 2]}>
                        <Typography variant="subtitle1">DEPARTMENT</Typography>
                        <Typography style={{ fontWeight: 600 }}>
                          {data.greenhouseJob.departments
                            ? data.greenhouseJob.departments[0].name
                            : ''}
                        </Typography>
                      </Box>
                      {data.greenhouseJob.offices && (
                        <Box mt={[1, 2]}>
                          <Typography variant="subtitle1">LOCATION</Typography>
                          {data.greenhouseJob.offices.map((item, index) => (
                            <React.Fragment key={index}>
                              <Typography style={{ fontWeight: 600 }}>
                                {item.name}
                              </Typography>
                            </React.Fragment>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={[1, 2]}>
                        <Application
                          title={data.greenhouseJob.title}
                          jobId={data.greenhouseJob.gh_Id}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} className={classes.contentRight}>
                <StickyBox offsetTop={50} offsetBottom={50}>
                  <Box mt={3}>
                    <Typography component="h2" variant="h4">
                      Job details
                    </Typography>
                    <Grid container justify="space-between">
                      <Grid item xs={12}>
                        <Box mt={[1, 2]}>
                          <Typography variant="subtitle1">
                            DEPARTMENT
                          </Typography>
                          <Typography style={{ fontWeight: 600 }}>
                            {data.greenhouseJob.departments
                              ? data.greenhouseJob.departments[0].name
                              : ''}
                          </Typography>
                        </Box>
                        {data.greenhouseJob.offices && (
                          <Box mt={[1, 2]}>
                            <Typography variant="subtitle1">
                              LOCATION
                            </Typography>
                            {data.greenhouseJob.offices.map((item, index) => (
                              <React.Fragment key={index}>
                                <Typography style={{ fontWeight: 600 }}>
                                  {item.name}
                                </Typography>
                              </React.Fragment>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={[1, 2]}>
                          <Application
                            title={data.greenhouseJob.title}
                            jobId={data.greenhouseJob.gh_Id}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </StickyBox>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Layout>
  );
}

//export default withStyles(styles)(Job);

export default compose(withWidth())(withStyles(styles)(Job));

Job.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  width: PropTypes.string.isRequired,
};

export const query = graphql`
  query($id: Int!) {
    greenhouseJob(internal_job_id: { eq: $id }) {
      title
      content
      internal_job_id
      gh_Id
      departments {
        name
        gh_Id
      }
      offices {
        location
        name
      }
    }
    departmentsJson {
      heroImageTechnology {
        image {
          publicURL
        }
      }
      heroImageDefault {
        image {
          publicURL
        }
      }
    }
    allPrismicDepartment {
      edges {
        node {
          data {
            greenhouse_id
            banner_image {
              url
            }
          }
        }
      }
    }
  }
`;
