import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OverlayImage from 'images/job_hero_overlay.png';

JobHero.propTypes = {
  passeddata: PropTypes.object.isRequired,
  dpId: PropTypes.number,
};

function JobHero(props) {
  const { passeddata, dpId } = props;
  return (
    <Box
      style={{
        backgroundColor: '#0a9828',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      py={12}
    >
      <Box
        component={Grid}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={10} lg={6}>
          <Typography
            component="h1"
            variant="h1"
            color="textSecondary"
            align="center"
          >
            {passeddata.greenhouseJob.title}
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
}

export default JobHero;
