import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = ({ palette }) => ({
  text: {
    cursor: 'pointer',
    borderRadius: '5px',
    border: '1px solid',
    padding: '6px 14px',
    color: palette.text.secondary,
    backgroundColor: props => props.color,
    textTransform: 'capitalize',
    fontWeight: 400,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: 'white',
      backgroundColor: props => props.color,
    },
  },
});

function BtnFunc(props) {
  const { classes, content, handleClick } = props;
  return (
    <Box display="flex">
      <Box display="flex" alignItems="center">
        <Button
          onClick={handleClick}
          className={classes.text}
          aria-label={content}
        >
          <Typography
            component="span"
            variant="body1"
            style={{ fontWeight: 600 }}
          >
            {content}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

BtnFunc.propTypes = {
  handleClick: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(BtnFunc);
