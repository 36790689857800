import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const Query = graphql`
  query JobDataQuery {
    allPrismicJob {
      edges {
        node {
          data {
            gh_id
            page_title {
              text
            }
            page_keywords {
              text
            }
            page_description {
              text
            }
            social_sharing_image {
              url
            }
          }
        }
      }
    }
    allPrismicDepartment {
      edges {
        node {
          data {
            greenhouse_id
            title {
              text
            }
            job_hero_image_default {
              url
            }
          }
        }
      }
    }
  }
`;

function JobHelmet(props) {
  const { ghdata, dpId } = props;
  return (
    <StaticQuery
      query={`${Query}`}
      render={data => <JobHelmetData data={data} ghdata={ghdata} dpId={dpId} />}
    />
  );
}

JobHelmet.propTypes = {
  ghdata: PropTypes.object.isRequired,
  dpId: PropTypes.number,
};

function JobHelmetData(props) {
  const { data, ghdata, dpId } = props;
  //console.log(data);
  //console.log(ghdata);
  var metadata = null;
  var heroImageFluid = null;
  if (data.allPrismicJob && data.allPrismicJob.edges) {
    data.allPrismicJob.edges.map(item => {
      if (item.node.data.gh_id == ghdata.greenhouseJob.gh_Id) {
        metadata = item.node.data;
      }
    });
  }

  // Check if department this job is in, is in Prismic and has a hero image
  if (
    data.allPrismicDepartment &&
    data.allPrismicDepartment.edges &&
    data.allPrismicDepartment.edges[0] &&
    heroImageFluid == null
  ) {
    data.allPrismicDepartment.edges.map(item => {
      if (item.node.data.greenhouse_id == dpId) {
        if (
          item.node.data.job_hero_image_default &&
          item.node.data.job_hero_image_default.url
        ) {
          heroImageFluid = item.node.data.job_hero_image_default.url;
        }
      }
    });
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>OVO Careers | {ghdata.greenhouseJob.title}</title>
        <meta content={ghdata.greenhouseJob.title} property="og:title" />
        <meta content={ghdata.greenhouseJob.title} name="og:site_name" />
        <meta content={ghdata.greenhouseJob.title} name="twitter:title" />
      </Helmet>
      {metadata && metadata.page_title && metadata.page_title.text && (
        <Helmet>
          <meta content={metadata.page_title.text} property="og:title" />
          <meta content={metadata.page_title.text} name="og:site_name" />
          <meta content={metadata.page_title.text} name="twitter:title" />
        </Helmet>
      )}
      {metadata && metadata.page_description && metadata.page_description.text && (
        <Helmet>
          <meta content={metadata.page_description.text} name="description" />
          <meta
            content={metadata.page_description.text}
            property="og:description"
          />
          <meta
            content={metadata.page_description.text}
            name="twitter:description"
          />
        </Helmet>
      )}
      {metadata && metadata.page_keywords && metadata.page_keywords.text && (
        <Helmet>
          <meta name="keywords" content={metadata.page_keywords.text} />
        </Helmet>
      )}
      {heroImageFluid && (
        <Helmet>
          <meta
            content={`${heroImageFluid}&q=50&lossless=1&crop=center&w=1024&h=512&txt=${ghdata.greenhouseJob.title}&txt-size=62&txt-color=fff&txt-align=middle,center&txt-font=Montserrat%20Condensed%20Medium&txt-fit=max`}
            property="og:image"
          />
          <meta
            content={`${heroImageFluid}&q=50&lossless=1&crop=center&w=1024&h=512&txt=${ghdata.greenhouseJob.title}&txt-size=62&txt-color=fff&txt-align=middle,center&txt-font=Montserrat%20Condensed%20Medium&txt-fit=max`}
            property="og:image:secure_url"
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta content="1024" property="og:image:width" />
          <meta content="512" property="og:image:height" />
          <meta
            content={`${heroImageFluid}&q=50&lossless=1&crop=center&w=1024&h=512&txt=${ghdata.greenhouseJob.title}&txt-size=62&txt-color=fff&txt-align=middle,center&txt-font=Montserrat%20Condensed%20Medium&txt-fit=max`}
            name="twitter:image"
          />
          <meta content="1024" name="twitter:image:width" />
          <meta content="512" name="twitter:image:height" />
        </Helmet>
      )}
    </React.Fragment>
  );
}

JobHelmetData.propTypes = {
  data: PropTypes.object.isRequired,
  ghdata: PropTypes.object.isRequired,
  dpId: PropTypes.number,
};

export default JobHelmet;
